import React, { useEffect, useRef } from "react";
import QRCode from "qrcode.react";
import { Button } from "@mantine/core";

export function QrDisplay({qrData, qrLabel, assetName }) {
  const canvasId = `qr-gen-${qrData}`;
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById(canvasId);
    const ctx = canvas.getContext("2d");
    ctx.font = "1.5px Helvetica";
    ctx.fillText(`${assetName} - ${qrLabel}`,6,3);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${assetName}-${qrLabel}-qr-code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const imageSettings = {
    src:"../../captiva-mark.png",
    x: 0,
    y: -20,
    height: 10,
    width: 10
  }
  return (
    <div>
      <h1 style={{marginBottom: 0}}>Asset QR code </h1>
      <h3 style={{marginBottom: 0}}>Print and stick to asset for easier selection</h3>
      <br />
      <QRCode
        id={canvasId}
        value={`${assetName}:${qrData}`}
        size={400}
        level={"H"}
        includeMargin={true}
        imageSettings={
          imageSettings
        }
        style={{border: '1px solid gray'}}
      />
      <p>
        <Button type="button" color={"indigo"} onClick={downloadQRCode} style={{width: '100%'}}>
          Download QR Code
        </Button>
      </p>
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
