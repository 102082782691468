import React, {
    useState,
  } from "react";
  
  import {
    ActionIcon,
    Tooltip,
  } from "@mantine/core";

  import {
    CircleDotted,
    EyeCheck,
    Robot,
  } from "tabler-icons-react";

export default function VerifiedIcon({ element }) {
    const [hasHumanReview] = useState(element.transactionReviewed);
    const [hasAIreview] = useState(!!element["autoVerified"]);
    const [reviewData] = useState(
      hasAIreview ? checkPassRate(element.autoVerified) : {}
    );
    const [opened, setOpened] = useState(false);
  
    function checkPassRate(elementVerificationObject) {
      const portionPassed =
        Object.values(elementVerificationObject.verifiedData)?.filter(
          (check) => !!check
        ).length || 0;
      const failedChecks = Object.keys(elementVerificationObject.verifiedData)
        ?.filter((key) => !elementVerificationObject.verifiedData[key])
        .join(", ");
      const total =
        Object.keys(elementVerificationObject?.verifiedData).length || 0;
      return {
        checksPassed: portionPassed,
        total,
        passed: portionPassed === total,
        failedChecks,
      };
    }
  
    return (
      <>
        {hasHumanReview ? (
          <>
            <Tooltip
              wrapLines
              opened={opened}
              openDelay={300}
              label={`Human reviewed`}
            >
              <ActionIcon
                onClick={() => setOpened(!opened)}
                onMouseOver={() => setOpened(true)}
                onMouseLeave={() => setOpened(false)}
              >
                <EyeCheck color="green" />
              </ActionIcon>
            </Tooltip>
          </>
        ) : (
          <>
            {hasAIreview ? (
              <>
                <Tooltip
                  wrapLines
                  width={250}
                  openDelay={300}
                  opened={opened}
                  label={`${reviewData.checksPassed}/${
                    reviewData.total
                  } data match - ${element.autoVerified.source} ${
                    reviewData.failedChecks.length > 0
                      ? `\n(${reviewData.failedChecks})`
                      : ""
                  }\n*image text recognition*`}
                >
                  <ActionIcon
                    onClick={() => setOpened(!opened)}
                    onMouseOver={() => setOpened(true)}
                    onMouseLeave={() => setOpened(false)}
                  >
                    <Robot color={reviewData.passed ? "green" : "orange"} />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              <>
                {!!element["transactionReviewed"] ? (
                  <EyeCheck color="green" />
                ) : (
                  <CircleDotted color="gray" />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }