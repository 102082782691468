import React, { useEffect } from "react";
import { Card, Divider, Image, Loader, Space, Text } from "@mantine/core";
import { useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { TripTable } from "./tables/trip-table";

export function Trips({ auth, companyId }) {
  //
  const [flagsFetched, setFlagsFetched] = useState(false);
  const [isTripsEnabled, setIsTripsEnabled] = useState(false);
  const [isTripsFetched, setIsTripsFetched] = useState(true);
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    if (!flagsFetched && companyId) {
      async function fetchFlags() {
        const documentSnapshot = await getDoc(
          doc(db, "featureFlags", "hA0T8o3RipWMnJQDnw4p")
        );

        if (documentSnapshot.data().trips.includes(companyId)) {
          setIsTripsEnabled(true);
          setIsTripsFetched(false);
        }
        setFlagsFetched(true);
      }
      fetchFlags();
    }
  }, [companyId]);

  useEffect(() => {
    async function fetchTrips() {
      if (!isTripsFetched) {
        setIsTripsFetched(true);
        const tripsSnapshots = await getDocs(
          query(collection(db, "trips"), where("companyId", "==", companyId))
        );
        if (tripsSnapshots.size > 0) {
          const entries = [];
          tripsSnapshots.forEach((doc) =>
            entries.push({ id: doc.id, ...doc.data() })
          );
          setTrips(entries);
        }
      }
    }
    fetchTrips();
  }, [isTripsFetched]);

  return (
    <>
      {!flagsFetched ? (<Loader/>) : (
        <>
          {isTripsEnabled ? (
            <>
              <Text size="xl" weight="semibold">
                Trips
              </Text>
              <Space h="sm" />
              <Divider />
              <Space h="sm" />
              <TripTable
                elements={trips}
                auth={auth}
                companyId={companyId}
                reloadCallback={() => setIsTripsFetched(false)}
              />
            </>
          ) : (
            <>
              <Card withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Image
                    src="../../captiva-trip-logo.png"
                    width={300}
                    my={20}
                  ></Image>
                  <Text size="xl" weight="semibold">
                    Request early access to Captiva Trip via{" "}
                    <a href="mailto:admin@captivadata.com">email</a>
                  </Text>
                  <video
                    controls
                    width="339"
                    height="852"
                    src="https://firebasestorage.googleapis.com/v0/b/twodata-dev.appspot.com/o/marketing%2FTrips-Demo.mov?alt=media&token=a0a9007c-cd05-424b-b4c4-2305ce961525"
                  />
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Trips;
