import { auth } from "../../../firebase";

export const updateTemplate = async (
  templateName,
  templateId,
  companyId,
  data
) => {
  const token = await auth.currentUser.getIdToken();

  return await fetch(
    `https://europe-west3-twodata-dev.cloudfunctions.net/tenantapi/altercapture/${templateId}?` +
      new URLSearchParams({ templateName, companyId }),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "0f247291-b4bb-4895-91ac-ba26af7e55a2",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...data, companyId }),
    }
  )
    .then((resp) => {
      if (resp.ok) return resp.json();
    })
    .catch((error) => console.error(error));
};

export const deleteTemplate = async (templateName, templateId, companyId) => {
  const token = await auth.currentUser.getIdToken();

  return await fetch(
    `https://europe-west3-twodata-dev.cloudfunctions.net/tenantapi/deletecapture/${templateId}?` +
      new URLSearchParams({ templateName, companyId }),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "0f247291-b4bb-4895-91ac-ba26af7e55a2",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((resp) => {
      if (resp.ok) return { code: 200 };
    })
    .catch((error) => console.error(error));
};
