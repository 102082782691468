export const TankReadingConfig = {
  reportConfig: {
    reportId: "Ty67uDRHQ9tsDOXsBNKV",
  },
  fieldConfig: {
    deviceCaptured: {
      label: "Device capture date",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
    tankName: {
      label: "Tank name",
      tableRank: 2,
      detailsRank: 3,
      editable: false,
      type: "STRING",
    },
    levelLitres: {
      label: "Tank level",
      tableRank: 3,
      detailsRank: 2,
      editable: true,
      type: "NUMBER",
    },
    signedBy: {
      label: "Signed by",
      tableRank: -1,
      detailsRank: 6,
      editable: false,
      type: "STRING",
    },
    tankSizeLitres: {
      label: "Tank size",
      tableRank: 6,
      detailsRank: -1,
      editable: false,
      type: "NUMBER",
    },
    signedByImage: {
      label: "Signature",
      tableRank: -1,
      detailsRank: 7,
      editable: false,
      type: "IMAGE",
    },
    dipReadingPhoto: {
      label: "Dip reading picture",
      tableRank: -1,
      detailsRank: 5,
      editable: false,
      type: "IMAGE",
    },
    tankIsMobile: {
      label: "Tank is mobile",
      tableRank: -1,
      detailsRank: 8,
      editable: false,
      type: "BOOLEAN",
    },
    tankFuelType: {
      label: "Fuel type",
      tableRank: 5,
      detailsRank: 10,
      editable: false,
      type: "STRING",
    },
    createdBy: {
      label: "Creator",
      tableRank: 4,
      detailsRank: 11,
      editable: false,
      type: "STRING",
    },
    gpsLocation: {
      label: "Location",
      tableRank: -1,
      detailsRank: 12,
      editable: false,
      type: "COORDINATE",
    },
    isModified: {
      label: "Entry modified",
      tableRank: -1,
      detailsRank: 13,
      editable: false,
      type: "BOOLEAN",
    },
    companyId: {
      label: "Company ID",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    id: {
      label: "ID",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    documentNumber: {
      label: "Document #",
      tableRank: 3,
      detailsRank: 10,
      editable: false,
      type: "STRING",
    },
    syncCompleted: {
      label: "--INTERNAL--",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    },
    receivedOnServer: {
      label: "--INTERNAL--",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
    transactionDate: {
      label: "Date",
      tableRank: 0,
      detailsRank: 0,
      editable: true,
      type: "DATE",
    },
    transactionReviewed: {
      label: "Reviewed",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    }
  }
};
