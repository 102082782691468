// export enum TripStatus {
//     PLANNED = "PLANNED",
//     ALLOCATED = "ALLOCATED",
//     PRE_INSPECTED = "PRE_INSPECTED",
//     IN_PROGRESS = "IN_PROGRESS",
//     ARRIVED = "ARRIVED",
//     COMPLETED = "COMPLETED",
//   }
export const TripConfig = {    
    description: {type: "STRING", required: true, editable: true, label: "Description", rank: 0},
    status: {type: "STRING", required: true, editable: false, label: "Status", rank: 1},
    deliveryAddress: {type: "STRING", required: true, editable: true, label: "Delivery address", rank: 2},
    pickupAddress: {type: "STRING", required: true, editable: true, label: "Pickup address", rank: 3},
    fleetId: {type: "SELECT", required: false, editable: true, label: "Vehicle", rank: 4},
    driverId: {type: "SELECT", required: false, editable: true, label: "Driver", rank: 5},
    notes: {type: "STRING", required: false, editable: true, label: "Notes", rank: 6}, 
    creatorEmail: {type: "STRING", required: false, editable: false, label: "Creator email", rank: 10},
    // fleetGroupName: {type: "STRING", required: false, editable: false, label: "fleetGroupName", rank: 10},
    // fleetDescription: {type: "STRING", required: false, editable: false, label: "fleetDescription", rank: 10},
    // fleetOdoOrHourBased: {type: "STRING", required: false, editable: false, label: "fleetOdoOrHourBased", rank: 10},
    // fleetGroupId: {type: "STRING", required: false, editable: false, label: "fleetGroupId", rank: 10},
    // fleetRegistration: {type: "STRING", required: false, editable: false, label: "fleetRegistration", rank: 10},
    // fleetDisplayName: {type: "STRING", required: false, editable: false, label: "fleetDisplayName", rank: 10},
    // fleetUseFleetForRebateClaim: {type: "STRING", required: false, editable: false, label: "fleetUseFleetForRebateClaim", rank: 10},
    // driverNameAndSurname: {type: "STRING", required: false, editable: false, label: "driverNameAndSurname", rank: 10},
    // driverEmployeeCode: {type: "STRING", required: false, editable: false, label: "driverEmployeeCode", rank: 10},
    // driverIdentification: {type: "STRING", required: false, editable: false, label: "driverIdentification", rank: 10},
    // companyId: {type: "STRING", required: false, editable: false, label: "companyId", rank: 10},
    // isTrackingActive: {type: "STRING", required: false, editable: false, label: "isTrackingActive", rank: 10},
    // creatorUid: {type: "STRING", required: false, editable: false, label: "creatorUid", rank: 10},
    // locationSnapshots: {type: "STRING", required: false, editable: false, label: "locationSnapshots", rank: 1},
    // capturedTripArrive: {type: "STRING", required: false, editable: false, label: "capturedTripArrive", rank: 1},
    // capturedTripPreInspection: {type: "STRING", required: false, editable: false, label: "capturedTripPreInspection", rank: 1},
    // capturedTripDepart: {type: "STRING", required: false, editable: false, label: "capturedTripDepart", rank: 1},
    // capturedTripCheckPoint: {type: "STRING", required: false, editable: false, label: "capturedTripCheckPoint", rank: 1},
    // capturedTripComplete: {type: "STRING", required: false, editable: false, label: "capturedTripComplete", rank: 1},
    // capturedTripDelay: {type: "STRING", required: false, editable: false, label: "capturedTripDelay", rank: 1},
};

// {
//     "deliveryAddress": "2 Theta St ",
//     "id": "1MYqIjL6eF28r6rMrmd6",
//     "creatorEmail": "adriaanventer94@gmail.com",
//     "fleetGroupName": "Private",
//     "driverId": "AZAPl0Dx6MWueGKjCnYy",
//     "driverNameAndSurname": "Test user",
//     "description": "Something",
//     "fleetUseFleetForRebateClaim": true,
//     "status": "ALLOCATED",
//     "driverEmployeeCode": "AAA - new",
//     "driverIdentification": "12345",
//     "fleetDescription": "Black 5.7L Petrol",
//     "fleetOdoOrHourBased": "Kilometers",
//     "pickupAddress": "1 Beta st",
//     "fleetGroupId": "w3qEsVmlQNS28nD2oAWt",
//     "companyId": "IirR5jhUe5AM2voCH3Qn",
//     "fleetId": "Te7CljZOv0DDqgcANZuX",
//     "fleetRegistration": "YPG324GP",
//     "fleetDisplayName": "Land Cruiser VX",
//     "isTrackingActive": false,
//     "notes": "Bits and bobs",
//     "creatorUid": "PwM2MuubYyUcoprxZj3ErpItFU73",
//     "capturedTripArrive": [],
//     "locationSnapshots": [],
//     "capturedTripPreInspection": [],
//     "capturedTripDepart": [],
//     "capturedTripCheckPoint": [],
//     "capturedTripComplete": [],
//     "capturedTripDelay": [],
// };
  