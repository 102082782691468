import React, { useEffect, useState } from "react";

import { Button, Modal, Notification, Table } from "@mantine/core";

import { AssetEditor } from "../../components/shared/asset-editor";
import { Plus } from "tabler-icons-react";
import { db } from "../../firebase";
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { TripConfig } from "../../components/listings/configurations/trip-config";

export function TripTable({ elements, auth, reloadCallback, companyId }) {
  const newTrip = {
    description: "", 
    status: "PLANNED", 
    deliveryAddress: "", 
    pickupAddress: "", 
    driverId: "", 
    driverNameAndSurname: "",
    fleetUseFleetForRebateClaim: false,
    fleetId: "", 
    fleetGroupId: "",
    fleetGroupName: "",
    fleetDescription: "",
    fleetRegistration: "",
    fleetDisplayName: "",
    fleetOdoOrHourBased: "",
    driverEmployeeCode: "",
    driverIdentification: "",
    companyId: companyId,
    isTrackingActive: false,
    notes: "",
    creatorEmail: auth?.claims?.email,
    creatorUid: auth?.claims?.user_id,
    locationSnapshots: [],
    capturedTripArrive: [],
    capturedTripPreInspection: [],
    capturedTripDepart: [],
    capturedTripCheckPoint: [],
    capturedTripComplete: [],
    capturedTripDelay: [],
  };
  const [editedElement, setEditedElement] = useState(undefined);

  const [isFetchedDependencies, setIsFetchedDependencies] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicleGroups, setVehicleGroups] = useState([]);
  const [message, setMessage] = useState(undefined);
  const [messageColor, setMessageColor] = useState('green');

  const handleEdit = () => {
    setIsFetchedDependencies(false);
  }

  const handleSave = async (updatedTrip) => {
    if(!updatedTrip) {
      reloadCallback();
      setMessageColor("green")
      setMessage("Successfully deleted!");
      return;
    }
    const vehicle = vehicles.find(v => v.id === updatedTrip.fleetId);
    const driver = drivers.find(d => d.id === updatedTrip.driverId);
    let group;
    if(vehicle) {
      group = vehicleGroups.find(g => g.id === vehicle.group);
    }
    // if the current trip is planned but has a driver it becomes allocated
    // otherwise just keep the existing status
    if(editedElement.status === "PLANNED" && updatedTrip.fleetId) {
      updatedTrip.status = "ALLOCATED";
    }
    const tripToSave = {
      ...editedElement, 
      ...updatedTrip,
      fleetGroupId: group?.id || "none",
      fleetGroupName: group?.name || "none",
      fleetDisplayName: vehicle?.name || "unassigned",
      fleetDescription: vehicle?.description || "unassigned",
      fleetRegistration: vehicle?.registrationNumber || "unassigned",
      fleetOdoOrHourBased: vehicle?.measurementUnit || "unassigned",
      driverNameAndSurname: driver?.fullName || "unassigned",
      driverEmployeeCode: driver?.employeeCode || "unassigned",
      driverIdentification: driver?.identification || "unassigned",
    }
    try {
      // handle save here
      if (tripToSave.id) {
        // save
        const docRef = doc(db, 'trips', tripToSave.id);
        await updateDoc(docRef, tripToSave);
        setMessageColor("green")
        setMessage("Successfully updated!");
        reloadCallback();
      } else {
        // create
        const docRef = await addDoc(collection(db, 'trips'), tripToSave);
        await updateDoc(docRef, {id: docRef.id});
        setMessageColor("green")
        setMessage("Successfully created!");
        reloadCallback();
      }
    } catch (error) {
      console.log(error);
      setMessageColor("red");
      setMessage("Save unsuccessful.", error.toString());
    }
  }

  useEffect(() => {
    async function fetchDeps() {
      if(!isFetchedDependencies) {
        setIsFetchedDependencies(true)
        const fleetSnapshots = await getDocs(
          query(collection(db, "fleet"), where("companyId", "==", companyId))
        );
        if (fleetSnapshots.size > 0) {
          const entries = [];
          fleetSnapshots.forEach((doc) =>
            entries.push({ id: doc.id, ...doc.data() })
          );
          entries.unshift({id: "", name: "No assignment"})
          setVehicles(entries);
        }

        const driverSnapshots = await getDocs(
          query(collection(db, "fleetOperators"), where("companyId", "==", companyId))
        );
        if (driverSnapshots.size > 0) {
          const driverEntries = [];
          driverSnapshots.forEach((doc) =>
            driverEntries.push({ id: doc.id, ...doc.data() })
          );
          driverEntries.unshift({id: "", fullName: "No assignment"})
          setDrivers(driverEntries);
        }

        const fleetGroupSnapshots = await getDocs(
          query(collection(db, "fleetGroups"), where("companyId", "==", companyId))
        );
        if (fleetGroupSnapshots.size > 0) {
          const groupEntries = [];
          fleetGroupSnapshots.forEach((doc) =>
            groupEntries.push({ id: doc.id, ...doc.data() })
          );
          setVehicleGroups(groupEntries);
        }
      }
    }
    fetchDeps();
  }, [isFetchedDependencies])

  const closeEdit = () => setEditedElement(undefined);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.description}</td>
      <td>{element.status}</td>
      <td>{element.pickupAddress}</td>
      <td>{element.deliveryAddress}</td>
      <td>{element.driverNameAndSurname}</td>
      <td>{element.tripToken ? <a href={`https://captivatrip.web.app/?tripToken=${element.tripToken}`} target="_blank">Tracking page</a> : "Not started"}</td>
      <td>
        <Button
          color={"cap-navy"}
          variant="outline"
          onClick={() => {
            setEditedElement(element);
            handleEdit();
          }}
        >
          Edit
        </Button>
      </td>
    </tr>
  ));

  return (
    <>
      <Table
        width={"100%"}
      >
        <thead>
          <tr>
            <th>Description</th>
            <th>Status</th>
            <th>Pickup address</th>
            <th>Delivery address</th>
            <th>Driver</th>
            <th>Tracking</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {editedElement && (
        <Modal opened onClose={closeEdit} title="Trip">
          <AssetEditor
            asset={editedElement}
            assetConfig={TripConfig}
            collection="trips"
            auth={auth}
            dependencyList={{"fleetId": [...vehicles], "driverId": [...drivers]}}
            dependencyLabel={{"fleetId": "name", "driverId": "fullName"}}
            useCallbackOnSave
            reloadCallback={(updatedTrip) => {
              handleSave(updatedTrip)
              closeEdit();
            }}
          ></AssetEditor>
        </Modal>
      )}
      <Button
        onClick={() => setEditedElement(newTrip)}
        fullWidth
        my="xl"
        color="cap-navy"
        variant="outline"
        leftIcon={<Plus />}
      >
        Create new
      </Button>
      {message && <Notification color={messageColor} onClose={() => setMessage("")}>{message}</Notification>}
    </>
  );
}
