import React, { useState } from "react";

import { Button, Modal, Table } from "@mantine/core";

import { QrDisplay } from "../../components/shared/qr-display";

import { VehicleAssetConfig } from "../../components/listings/configurations/assets/vehicle-asset-config";
import { AssetEditor } from "../../components/shared/asset-editor";
import { Plus, Qrcode } from "tabler-icons-react";

export function VehicleTable({
  elements,
  auth,
  reloadCallback,
  vehicleGroups,
}) {
  const newVehicle = {
    name: "",
    registrationNumber: "",
    group: undefined,
    description: "",
    isActive: true,
    useForRebateClaim: true,
    measurementUnit: "KM",
  };
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [editedElement, setEditedElement] = useState(undefined);
  const closeEdit = () => setEditedElement(undefined);
  const rows = elements.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.registrationNumber}</td>
      <td>{element.description}</td>
      <td>
        {vehicleGroups.find((group) => group.id === element.group)?.name ||
          "Ungrouped"}
      </td>
      <td>{element.isActive.toString()}</td>
      <td>
       <Button
          color={"cap-navy"}
          variant="outline"
          onClick={() => {
            setQrLabel(element.name);
            setQrValue(element.id);
            setIsExpanded(true);
          }}
          leftIcon={<Qrcode />}
        >
          Print QR
        </Button>
      </td>
      <td>
        <Button
          color={"cap-navy"}
          variant="outline"
          onClick={() => {
            setEditedElement(element);
          }}
        >
          Edit
        </Button>
      </td>
    </tr>
  ));

  return (
    <>
      <Table width={"100%"}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Registration</th>
            <th>Description</th>
            <th>Group</th>
            <th>Active</th>
            <th>Asset QR</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <Modal
          opened={isExpanded}
          onClose={() => setIsExpanded(false)}
          closeOnClickOutside
        >
          <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"Vehicle"} />
        </Modal>
      </Table>
      {editedElement && (
        <Modal opened onClose={closeEdit} title="Vehicle">
          <AssetEditor
            asset={editedElement}
            assetConfig={VehicleAssetConfig}
            collection="fleet"
            auth={auth}
            reloadCallback={() => {reloadCallback(); closeEdit()}}
            dependencyList={{"group": [...vehicleGroups]}}
            dependencyLabel={{"group": "name"}}
          ></AssetEditor>
        </Modal>
      )}
      <Button onClick={() => setEditedElement(newVehicle)}  fullWidth my="xl" color="cap-navy" variant="outline" leftIcon={<Plus />}>
        Create new
      </Button>
    </>
  );
}
